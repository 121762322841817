import React from 'react';
import facebook from '../../images/social_media_icon_fb.png';
import vk from '../../images/social_media_icon_vk.png';
import insta from '../../images/social_media_icon_insta.png';
import youtube from '../../images/social_media_icon_youtube.png';

const Footer = () => (
  <div id="landing-footer" className="footer">
    <h4 className="custom-h2">© Ivan Khokhryakov und Nadezhda Provotorova</h4>
    <div className="social">
      <a href="https://vk.com/zielstudienkolleg" target="_blank" rel="noopener noreferrer">
        <img src={vk} width="50%" height="80%" alt="VK" />
      </a>
    </div>
    <div className="social">
      <a href="https://www.facebook.com/zielstudienkolleg/" target="_blank" rel="noopener noreferrer">
        <img src={facebook} width="50%" height="80%" alt="Facebook" />
      </a>
    </div>
    <div className="social">
      <a href="https://instagram.com/zielstudienkolleg" target="_blank" rel="noopener noreferrer">
        <img src={insta} width="50%" height="80%" alt="Instagram" />
      </a>
    </div>
    <div className="social">
      <a href="https://www.youtube.com/channel/UCbEhG_fiHO_94OlFi1FWaXQ" target="_blank" rel="noopener noreferrer">
        <img src={youtube} width="50%" height="80%" alt="YouTube" />
      </a>
    </div>

    <div className="footer-contact">
      <p>Иван Хохряков | ИНН: 660706007482</p>
      <p>г. Калининград, ул. Красная 109</p>
      <p>
        Электронная почта: <a href="mailto:zielstundienkolleg@gmail.com">zielstundienkolleg@gmail.com</a>
      </p>
      <p>
        <a href="https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2F%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D1%8B.pdf?alt=media&token=18d295a5-1cb0-4bf2-8ec8-e3ac084acb75" target="_blank" rel="noopener noreferrer">
          Договор оферта
        </a> | 
        <a href="https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2F%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=99bf3913-7012-4c2c-ac2c-3c65b916d3b5" target="_blank" rel="noopener noreferrer">
          Безопасность данных
        </a>
      </p>
    </div>
  </div>
);

export default Footer;




