import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { getFirestore, collection, doc, getDocs, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const db = getFirestore();

const ShopItem = () => {
    const [rawData, setRawData] = useState([]);
    const [, setStoreData] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [, setPurchased] = useState({});
    const [, setCartContainer] = useState([]);
    const [, setCTestNumber] = useState({});
    const [, setMathNumber] = useState({});
    const [, setDeutschNumber] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const userDoc = doc(db, 'Users', getAuth().currentUser.uid);
            const purchasesCollection = collection(userDoc, 'Purchases');
            const querySnapshot = await getDocs(purchasesCollection);

            let rootObject = {};
            querySnapshot.forEach(doc => {
                let topics = [];
                Object.entries(doc.data()).forEach(topic => {
                    topics = [...topics, topic[0]];
                });
                rootObject[doc.id] = topics;
            });
            setPurchased(rootObject);

            const getMarker = async () => {
                const snapshot = await getDocs(collection(db, 'Stk'));
                return snapshot.docs.map(doc => doc.data());
            };

            const getCart = async () => {
                const cartDoc = doc(userDoc, 'Cart', 'Shop');
                const snapshot = await getDoc(cartDoc);
                return snapshot.exists() ? snapshot.data()["Cart"] : [];
            };

            const getCount = async (subject) => {
                const subjectDoc = doc(db, 'Struktur', subject);
                const snapshot = await getDoc(subjectDoc);
                return snapshot.data();
            };

            const [cartData, cTestData, mathData, deutschData, markerData] = await Promise.all([
                getCart(),
                getCount('C-Test'),
                getCount('Mathematik'),
                getCount('Deutsch'),
                getMarker()
            ]);

            setCartContainer(cartData);
            setCTestNumber(cTestData);
            setMathNumber(mathData);
            setDeutschNumber(deutschData);
            setRawData(markerData);

            let storeData = [];
            markerData.forEach((course) => {
                let item = {
                    stkName: course.StkName,
                    stkData: course.Courses
                };
                storeData.push(item);
            });
            setStoreData(storeData);

            parseValues(storeData, rootObject, cTestData, mathData, deutschData);
        };

        fetchData();
    }, []);

    const parseValues = (storeData, purchased, cTestNumber, mathNumber, deutschNumber) => {
        let root = {
            value: 'store',
            label: 'Показать список штуденколлегов',
            showCheckbox: false,
            children: []
        };

        storeData.forEach((course) => {
            let level = {
                value: course.stkName,
                label: course.stkName,
                showCheckbox: false,
                children: []
            };

            root.children.push(level);

            Object.keys(course.stkData).forEach(key => {
                let item1 = {
                    value: `${course.stkName}-${key}`,
                    label: key,
                    children: []
                };

                level.children.push(item1);

                course.stkData[key].forEach(exam => {
                    let examName = Object.keys(exam)[0];
                    let item2 = {
                        value: `${course.stkName}-${key}-${examName}`,
                        label: examName,
                        children: []
                    };

                    item1.children.push(item2);

                    let isPurchased = purchased[examName];

                    exam[examName].forEach(topic => {
                        let isBought = isPurchased ? isPurchased.includes(topic) : false;
                        let topicNumber = null;
                        let labelSuffix = '';

                        if (examName === 'Deutsch') {
                            topicNumber = deutschNumber[topic];
                            labelSuffix = `: Всего: ${topicNumber ? topicNumber.total : 'неизвестно'}`;
                        } else if (examName === 'C-Test') {
                            topicNumber = cTestNumber[topic];
                            labelSuffix = `: Всего: ${topicNumber ? topicNumber.total : 'неизвестно'}`;
                        } else if (examName === 'Mathematik') {
                            topicNumber = mathNumber[topic];
                            labelSuffix = `: (Всего: ${topicNumber ? topicNumber.total : 'неизвестно'}, экз.: ${topicNumber ? topicNumber.exam : 'неизвестно'})`;
                        } else {
                            labelSuffix = `: Всего: неизвестно`;
                        }

                        let item3 = {
                            value: `${course.stkName}%${key}%${examName}%${topic}%${exam[examName].length}`,
                            label: `${topic}${labelSuffix}`,
                            disabled: isBought,
                            showCheckbox: !isBought
                        };

                        item2.children.push(item3);
                    });
                });
            });
        });

        setNodes([root]);
    };

    const handleCheck = (checked) => {
        console.log("Checked:", checked)
        setChecked(checked);
    };

    const handleExpand = (expanded) => {
        setExpanded(expanded);
    };

    const handleCheckout = () => {
        navigate('/cart', {
            state: {
                purchases: checked,
                storedata: rawData,
                origin: window.location.origin
            }
        });
    };

    return (
        <div className="col-md-9">
                <div className="profile-content" id="main">
                    <div className="warenTree2">
                        <h1>Магазин</h1>
                        <div>
                            <br/>
                            <p className="alert alert-warning"><strong>Внимание!</strong></p>
                        </div>
                        <div>
                            <p className="alert alert-danger">
                                Доступ к купленным материалам предоставляется <b> на один год</b> и <b>только</b> через мобильное приложение!
                            </p>
                        </div>
                        <form id="form">
                            {checked.length !== 0 &&
                                <button id="checkout" className="btn btn-primary" onClick={handleCheckout}>
                                    Купить
                                </button>
                            }
                        </form>
                        <br/>
                        <div className="tree-container" style={{ maxHeight: '500px', overflowY: 'auto', position: 'relative' }}></div>
                        <CheckboxTree
                            nodes={nodes}
                            checked={checked}
                            expanded={expanded}
                            onCheck={handleCheck}
                            onExpand={handleExpand}
                            icons={{
                                check: <span className="rct-icon rct-icon-check" />,
                                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                leaf: <span className="rct-icon rct-icon-leaf" />,
                            }}
                        />
                    </div>
                </div>
        </div>
    );
};

export default ShopItem;




