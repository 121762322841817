import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';

const auth = getAuth();
const db = getFirestore();

const StripeCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // useNavigate hook for programmatic navigation
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://zielstudienkolleg.de/shop', // Redirect URL for cancelled or unsuccessful payments
      },
      redirect: 'if_required'
    });

    if (error) {
      console.error("Error during payment confirmation: ", error);
      alert("Произошла ошибка при платеже!");
      setIsLoading(false);
      navigate('/shop'); // Redirect to /shop if there is an error
      return;
    }

    if (paymentIntent.status === 'succeeded') {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) {
          throw new Error("User ID is missing.");
        }

        const promises = props.items.map(async (item) => {
          const examCollection = item.Exam;
          const topic = item.Topic;

          if (!examCollection || !topic) {
            throw new Error("Exam collection or Topic is missing.");
          }

          const receiptDoc = doc(db, 'Users', userId, 'Purchases', examCollection);
          const expiryDate = new Date();
          expiryDate.setFullYear(expiryDate.getFullYear() + 1);

          await updateDoc(receiptDoc, {
            [topic]: expiryDate
          });
        });

        await Promise.all(promises);

        setIsLoading(false);
        navigate('/comp'); // Redirect after successful update
      } catch (updateError) {
        console.error("Error updating document: ", updateError);
        alert("Произошла ошибка при обновлении документа!");
        setIsLoading(false);
        navigate('/shop'); // Redirect to /shop if there is an error during the document update
      }
    } else {
      alert("Произошла ошибка при платеже!");
      setIsLoading(false);
      navigate('/shop'); // Redirect to /shop if the payment is not successful
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      spacingUnit: '2px',
      borderRadius: '5px',
    },
    rules: {
      '.Input': {
        padding: '10px',
      },
    },
  };

  return (
    <div className="payment-form" style={{ maxWidth: '400px', margin: '0 auto', padding: '5px' }}>
      {isLoading && (
        <center>
          Пожалуйста, подождите. Покупка в процессе обработки. Вы будете перенаправлены на страницу оплаты <br />
        </center>
      )}
      <form id="payment-element" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={{ appearance }} />
        <button type="submit" className="btn btn-main-md btn-primary" disabled={!stripe || isLoading} style={{ width: '100%', marginTop: '10px' }}>
          Bezahlen
        </button>
      </form>
    </div>
);

};

export default StripeCheckoutForm;


















