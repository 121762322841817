import React, { useEffect } from 'react';

const CookieConsent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//www.freeprivacypolicy.com/public/cookie-consent/4.1.0/cookie-consent.js";
    script.charset = "UTF-8";
    script.async = true;
    script.onload = () => {
      if (window.cookieconsent) {
        window.cookieconsent.run({
          "notice_banner_type": "headline",
          "consent_type": "implied",
          "palette": "light",
          "language": "ru",
          "page_load_consent_levels": ["strictly-necessary", "functionality", "tracking", "targeting"],
          "notice_banner_reject_button_hide": false,
          "preferences_center_close_button_hide": false,
          "page_refresh_confirmation_buttons": false,
          "website_name": "Ziel-Studienkolleg",
          "website_privacy_policy_url": "https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2FNutzungsvertrag_der_Plattform.pdf?alt=media&token=9e68fe40-f9b0-4e4e-9935-6ee5e7b58102"
        });
      }
    };
    document.head.appendChild(script);
  }, []);

  return (
    <noscript>
      Cookie Consent by <a href="https://www.freeprivacypolicy.com/">Free Privacy Policy Generator</a>
    </noscript>
  );
};

export default CookieConsent;







