import React, { useState } from 'react';
import axios from 'axios';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const YookassaWrapper = ({ amount, description, items }) => {
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const db = getFirestore();
  const auth = getAuth();

  const handlePayment = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://us-central1-ziel-studienkolleg.cloudfunctions.net/createPayment/create-payment', {
        amount: parseFloat(amount),
        description: description,
        userId: auth.currentUser.uid,
        items: items,
        email: email,
        phone: phone,
      });

      console.log('Response from server:', response.data);
      setPaymentUrl(response.data.confirmation.confirmation_url);

      await handleFirebase(response.data.id);
    } catch (error) {
      console.error("Error creating payment:", error);
      setError("Ошибка при создании платежа. Попробуйте снова.");
    } finally {
      setLoading(false);
    }
  };

  const handleFirebase = async (paymentId) => {
    try {
      const userId = auth.currentUser.uid;
      const receiptRef = doc(db, 'Users', userId, 'Receipts', paymentId);

      const receiptData = {
        Amount: amount,
        Currency: 'EUR',
        UserId: userId,
        Promo: description || null,
        Items: items || null,
        Gateway: "yookassa",
        Status: 100,
        PurchaseDate: new Date(),
        Successful: false,
        Email: email,
        Phone: phone,
      };

      await setDoc(receiptRef, receiptData);

      console.log('Receipt added to Firebase');
    } catch (firebaseError) {
      console.error("Error updating Firebase:", firebaseError);
    }
  };

  const handleReady = async () => {
    if (!email || !phone) {
      setError("Пожалуйста, заполните поля электронной почты и телефона.");
      return;
    }
    setError(null);
    setIsReady(true);
    await handlePayment(); // Вызываем функцию создания платежа сразу после нажатия "Готово"
  };

  return (
    <div style={styles.container}>
      {!isReady ? (
        <>
          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Электронная почта:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
            </label>
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Телефон:
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                style={styles.input}
              />
            </label>
          </div>
          {error && <div style={styles.error}>{error}</div>}
          <button onClick={handleReady} style={styles.button}>
            Готово
          </button>
        </>
      ) : (
        <>
          {loading ? (
            <div>Загрузка информации о платеже...</div>
          ) : paymentUrl ? (
            <div style={styles.linkContainer}>
              <a href={paymentUrl} target="_blank" rel="noopener noreferrer" style={styles.paymentLink}>
                Перейти к оплате
              </a>
            </div>
          ) : (
            <div>Ошибка при создании платежа. Попробуйте снова.</div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '14px',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  linkContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  paymentLink: {
    color: '#007bff',
    fontWeight: 'bold',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

export default YookassaWrapper;








