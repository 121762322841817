import React, { Component } from 'react';
import '../../index.css';
import { NavLink } from 'react-router-dom';

export default class Menu extends Component {
    handleLogout = () => {
        this.props.auth().then(() => {
            window.location.href = '/'; // Benutzer nach dem Ausloggen auf die Startseite weiterleiten
        });
    };

    render() {
        return (
            <div className="col-md-3">
                <div className="profile-content">
                    <div className="profile-userpic">
                        <img className="img-responsive" id="user-picture" alt="" src={this.props.imageUrl}></img>
                    </div>
                    <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            <p id="display-name">{this.props.displayName}</p>
                        </div>
                        <div className="profile-usertitle-job">
                            <p id="user-mail">{this.props.userEmail}</p>
                        </div>
                    </div>
                    <div className="profile-userbuttons">
                        <button id="signOut" className="btn btn-main-md btn-primary" onClick={this.handleLogout}>Выйти</button>
                    </div>
                    <div className="profile-usermenu">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink className="nav-link active" to="/list">
                                    <span className="glyphicon glyphicon-home" /> Мои покупки
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link active" to="/shop">
                                    <span className="glyphicon glyphicon-shopping-cart" /> Магазин
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link active" to="/support">
                                    <span className="glyphicon glyphicon-flag" /> Обратиться в поддержку
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

