import React, { useEffect, useState, useCallback } from 'react';
import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, deleteField } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Navigate, useLocation } from 'react-router-dom';
import spinner from '../../images/pacman-loader.gif';
import StripeWrapper from './StripeWrapper';
import YookassaWrapper from './YooKassaWrapper'; // Импортируем YookassaWrapper

const db = getFirestore();
const auth = getAuth();

const Kart = () => {
    const [, setFullset] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [, setPrices] = useState({});
    const [, setDiscounts] = useState({});
    const [total, setTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [totalWithDiscount, setTotalWithDiscount] = useState(0);
    const [, setReady] = useState(false);
    const [loadingPromo, setLoadingPromo] = useState(false);
    const [promoInputVisible, setPromoInputVisible] = useState(true);
    const [promoPhrase, setPromoPhrase] = useState(null);
    const [cuponDiscount, setCuponDiscount] = useState(null);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [promocoodeInfoMsg, setPromocoodeInfoMsg] = useState(null);
    const [, setOrigin] = useState(null);
    const [cartItems, setCartItems] = useState({});
    const [stripeVisible, setStripeVisible] = useState(false);
    const [stripeWrapperVisible, setStripeWrapperVisible] = useState(false);
    const [yookassaVisible, setYookassaVisible] = useState(false); // Добавляем состояние для Yookassa

    const location = useLocation();

    const calculateTotalWithDiscount = useCallback((total, discountValue) => {
        const totalWithDiscount = total - (total * (discountValue / 100));
        setTotalWithDiscount(totalWithDiscount);
    }, []);

    const checkDuplicates = useCallback((purchasesArray, discountsData) => {
        const completePurchaseList = [];
        const pos = [];

        for (let i = 0; i < purchasesArray.length; i++) {
            for (let j = i + 1; j < purchasesArray.length; j++) {
                if (purchasesArray[i].Exam === purchasesArray[j].Exam && purchasesArray[i].Topic === purchasesArray[j].Topic) {
                    pos.push(j);
                    break;
                }
            }
        }

        let newTotal = 0;
        let newDiscountTotal = 0;
        for (let i = 0; i < purchasesArray.length; i++) {
            if (!pos.includes(i)) {
                const newItem = purchasesArray[i];
                completePurchaseList.push(newItem);
                newTotal += parseFloat(newItem.Price);
                if (discountsData[newItem.Exam]) {
                    newDiscountTotal += parseFloat(discountsData[newItem.Exam]);
                }
            }
        }

        setPurchases(completePurchaseList);
        setTotal(newTotal);
        setDiscountTotal(newDiscountTotal);
        calculateTotalWithDiscount(newTotal, newDiscountTotal);
    }, [calculateTotalWithDiscount]);

    useEffect(() => {
        if (!location.state) {
            return;
        }

        const { purchases, storedata, origin } = location.state;

        const fetchPricesAndDiscounts = async () => {
            const pricesDoc = await getDoc(doc(db, 'Prices', 'Subjects'));
            const pricesData = pricesDoc.data();
            setPrices(pricesData);

            const discountsDoc = await getDoc(doc(db, 'Prices', 'Discounts'));
            const discountsData = discountsDoc.data();
            setDiscounts(discountsData);

            const purchasesArray = purchases.map(element => {
                const [stk, course, exam, topic] = element.split('%');
                const price = pricesData[exam];
                return { Stk: stk, Course: course, Exam: exam, Topic: topic, Price: price };
            });

            let newTotal = 0;
            purchasesArray.forEach(item => {
                newTotal += parseFloat(item.Price);
            });

            setPurchases(purchasesArray);
            setTotal(newTotal);
            checkDuplicates(purchasesArray, discountsData);

            // Сохранение выбранных курсов в Firebase
            const userId = auth.currentUser.uid;
            const userCartDocRef = doc(db, 'Users', userId, 'Cart', "Shop");

            // Проверяем, существует ли документ
            const userCartDoc = await getDoc(userCartDocRef);
            if (!userCartDoc.exists()) {
                await setDoc(userCartDocRef, { Cart: [] });
            }

            // Очищаем предыдущие данные корзины
            await updateDoc(userCartDocRef, {
                Cart: deleteField()
            });

            // Обновляем корзину с новыми данными
            purchasesArray.forEach(async (item) => {
                await updateDoc(userCartDocRef, {
                    Cart: arrayUnion(item)
                });
            });
        };

        setReady(true);
        setCartItems(purchases);
        setFullset(storedata);
        setOrigin(origin);
        fetchPricesAndDiscounts();
    }, [location.state, checkDuplicates]);

    const handleCheck = () => {
        setAgreedToTerms(!agreedToTerms);
    };

    const checkDiscount = async (event) => {
        event.preventDefault();
        const userpromo = event.target.inputPromo.value;

        if (userpromo) {
            setLoadingPromo(true);

            try {
                // Fetch the promo code document from Firestore
                const promoDoc = await getDoc(doc(db, 'DiscountCodes', userpromo));
                
                if (promoDoc.exists()) {
                    const promoData = promoDoc.data();
                    //console.log('promoData:', promoData); // Debugging line to check promoData

                    // Check if the Discount field exists
                    if (promoData.Discount && typeof promoData.Discount.Value === 'number') {
                        const discountValue = promoData.Discount.Value;

                        setLoadingPromo(false);
                        setPromoInputVisible(false);
                        setPromocoodeInfoMsg(null);
                        setCuponDiscount(`${discountValue}%`);
                        setPromoPhrase(userpromo);

                        // Recalculate the total with discount
                        calculateTotalWithDiscount(total, discountValue);
                    } else {
                        console.log('Discount field is missing or incorrect'); // Debugging line for missing fields
                        setLoadingPromo(false);
                        setPromocoodeInfoMsg('Промокод не найден либо неактивен');
                    }
                } else {
                    console.log('Promo code document does not exist'); // Debugging line for non-existent document
                    setLoadingPromo(false);
                    setPromocoodeInfoMsg('Промокод не найден либо неактивен');
                }
            } catch (error) {
                console.error('Error checking promo code:', error); // Debugging line for catching errors
                setLoadingPromo(false);
                setPromocoodeInfoMsg('Произошла ошибка при проверке промокода');
            }
        }
    };

    const openStripe = () => {
        setStripeVisible(true);
        setStripeWrapperVisible(true);
    };

    const closeStripe = () => {
        setStripeVisible(false);
    };

    const openYookassa = () => {
        setYookassaVisible(true);
    };

    const closeYookassa = () => {
        setYookassaVisible(false);
    };

    if (!cartItems) {
        return <Navigate to='/shop' />;
    }

    return (
        <div className="col-md-9">
            <div className="profile-content" id="main">
                <div>
                    <h1>Корзина</h1>
                    <strong>Ваши покупки:</strong>
                    <ul>
                        {purchases.map((item) => (
                            <li key={`${item.Stk}-${item.Course}-${item.Exam}-${item.Topic}`}>
                                {item.Stk}, {item.Course}, {item.Exam}, {item.Topic} - {item.Price}€
                            </li>
                        ))}
                    </ul>
                    <br />
                    <h4>Общая сумма: {total.toFixed(2)}€</h4>
                    <h4>Скидка: {discountTotal.toFixed(2)}% {cuponDiscount && <u>По купону: -{cuponDiscount}</u>}</h4>
                    <p className="text-danger"><h4>Цена с учетом скидки: {totalWithDiscount.toFixed(2)}€</h4></p>
                    {promocoodeInfoMsg && <h5>{promocoodeInfoMsg}</h5>}
                    {loadingPromo && <center><img src={spinner} alt="spinner" /></center>}
                    <form className="form-inline" onSubmit={checkDiscount}>
                        <div className="form-group mx-sm-3 mb-2">
                            <label htmlFor="inputPromo" className="sr-only">Промокод</label>
                            {promoInputVisible && <input type="text" className="form-control" id="inputPromo" placeholder="Промокод" />}
                        </div>
                        {promoInputVisible && <button type="submit" className="btn btn-success mb-2">Применить</button>}
                    </form>
                    <br />
                    <div className="checkbox">
                        <label htmlFor="terms-check" className="checkbox-inline">
                            <input type="checkbox" id="terms-check" onChange={handleCheck} checked={agreedToTerms} />
                            Я соглашаюсь с условиями, описанными <button style={{ color: 'blue', textDecoration: 'underline', background: 'none', border: 'none' }}><a href="https://firebasestorage.googleapis.com/v0/b/ziel-studienkolleg.appspot.com/o/Documents%2F%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=99bf3913-7012-4c2c-ac2c-3c65b916d3b5" target="_blank" rel="noopener noreferrer">здесь</a></button>
                        </label>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-2">
                                <button
                                    onClick={openStripe}
                                    className={`btn btn-block ${agreedToTerms ? 'btn-success' : 'btn-secondary'}`}
                                    disabled={!agreedToTerms}
                                >
                                    Оплата
                                </button>
                            </div>
                            <div className="col-12">
                                <button
                                    onClick={openYookassa}
                                    className={`btn btn-block ${agreedToTerms ? 'btn-success' : 'btn-secondary'}`}
                                    disabled={!agreedToTerms}
                                >
                                    Оплата(Россия)
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${stripeVisible ? 'active' : ''}`} onClick={closeStripe}></div>
            <div className={`stripe-container ${stripeVisible ? 'active' : ''}`}>
                {stripeWrapperVisible && <StripeWrapper amount={totalWithDiscount.toFixed(2)} currency="EUR" description={promoPhrase} items={purchases} />}
            </div>
            <div className={`overlay ${yookassaVisible ? 'active' : ''}`} onClick={closeYookassa}></div>
            <div className={`stripe-container ${yookassaVisible ? 'active' : ''}`}>
                {yookassaVisible && <YookassaWrapper amount={totalWithDiscount.toFixed(2)} currency="RUB" description={promoPhrase} items={purchases} />}
            </div>
        </div>
    );
};

export default Kart;





























